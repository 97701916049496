import { useEffect, useMemo } from 'react';
import SceneObjects from './SceneObjects';
import { useAtom, useAtomValue } from 'jotai';
import { activeSceneAtom, objectsStatesAtom } from '../../../atoms/scene';
import { activeScenarioAtom } from '../../../atoms/scenario';
import { sceneList } from '../../../content/scenes/scene_list';
import BackgroundCharacters from '../models/characters/BackgroundCharacters/BackgroundCharacters';
import Environment from '../models/environments/Environment';
import { useThree } from '@react-three/fiber';
import { Color, Mesh, MeshStandardMaterial } from 'three';
import { threeLoadedAtom } from '../../../atoms/three';
import { scenarioDataAtom } from '../../../atoms/content';

const SceneManager = () => {

	const [activeSceneId, setActiveSceneId] = useAtom(activeSceneAtom);
	const activeScenarioId = useAtomValue(activeScenarioAtom);
	const scenarioData = useAtomValue(scenarioDataAtom);
	const [, objectsDispatch] = useAtom(objectsStatesAtom);

	useEffect(() => {
		if(!activeScenarioId || !sceneList[activeScenarioId])return;
		setActiveSceneId(Object.keys(sceneList[activeScenarioId])[0]);
	}, [activeScenarioId]);

	const sceneData = useMemo(() => {
		if (!activeSceneId || !activeScenarioId || !sceneList[activeScenarioId][activeSceneId]) return;
		objectsDispatch({ type: 'reset-objects' });
		Object.entries(sceneList[activeScenarioId][activeSceneId].objects).map(([key, sceneObject]) => {
			objectsDispatch({ type: 'add-object', payload: { key: key, data: sceneObject } });
		});
		return sceneList[activeScenarioId][activeSceneId];
	}, [activeSceneId]);

	const {scene} = useThree();
	
	const threeLoaded = useAtomValue(threeLoadedAtom);

	// the typing logic here is flawed.
	useEffect(() => {
		const custom_data = scenarioData?.custom_data;
		scene.traverse((object) => {
			const objectAsMesh = (object as Mesh);
			if(object.type === 'Mesh'){
				const material = (objectAsMesh.material as MeshStandardMaterial);
				if(material.name.includes('custom_color_primary') && custom_data?.overwrite_primary_color){
					material.color = new Color(custom_data.overwrite_secondary_color);
				} 
				else if(material.name.includes('custom_color_secondary') && custom_data?.overwrite_secondary_color){
					material.color = new Color(custom_data.overwrite_secondary_color);
				}
			}
		});
	},[scene, threeLoaded]);

	return (
		<>
			{sceneData && <Environment sceneData={sceneData}/>}
			{/* Background characters of the activeScene */}
			{sceneData && sceneData.background_characters && <BackgroundCharacters characters={sceneData.background_characters}/>}
			{/* objects in scene */}
			{sceneData && Object.keys(sceneData.objects).length != 0 && <SceneObjects objects={sceneData.objects} />}
		</>
	);
};

export default SceneManager;