import { ReactNode, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './global';
import { theme } from './theme';

const CustomThemeProvider = (props: { children: ReactNode }) => {

	const [loaded, setloaded] = useState(false);
	const [platformTheme, setPlatformTheme] = useState({ colors: {} });

	useEffect(() => {
		initialize();
	}, []);

	const initialize = async () => {
		if (location.hostname !== 'localhost' && location.hostname !== '') {
			const url = `https://${location.hostname}/api/config`;
			await fetch(url).then(async (config) => {
				const parsedConfig = await config.json();
				const primaryColor = parsedConfig.themes[0].colors.primary_color;
				if (primaryColor) setPlatformTheme({ colors: { primary: primaryColor } });
			}).catch((e) => e);
		} 
		setloaded(true);
	};

	return (
		<>
			{loaded &&
				<ThemeProvider theme={{ ...theme, colors: { ...theme.colors, ...platformTheme.colors } }}>
					<GlobalStyle />
					{props.children}
				</ThemeProvider>
			}
		</>
	);

};

export default CustomThemeProvider;