import { atomWithStorage } from 'jotai/utils';
import { Expression, Meshes } from '../components/Three/models/characters/Human/Human';
import { EllieEmotions } from '../components/EllieAvatar';
import { atom } from 'jotai';

export const availableLanguagesAtom = atom<string[] | null>([]);
export const scenarioDataAtom = atomWithStorage<Scenario | undefined>('scenario_data', undefined);

export type Translations = {[language:string]: {[key:string]:string}};

// types for data
export type Content = {
	date_created: string,
	scenario: string,
	data: Scenario & {translations?: Translations}
};

export type Scenario = {
	endings: {[ending_id: string]: Ending}
	messages: {[message_id: string]: Message<StandardMessage | ContextMessage>}
	items: {[item_id: string]: Item},
	characters: {[character_id: string]: Character},
	starting_message?: string 
	environment: string
	custom_data: {
		overwrite_primary_color: 'string' | undefined,
		overwrite_secondary_color: 'string' | undefined,
	} | undefined;
};

export type Ending = {
	achieved: boolean,
};

export type Message<T extends StandardMessage | ContextMessage> = {
	follow_up_message?: string,
	events: GameEvent[]
	ending?: string
} & T;

export type Answer = {		
	/**References a message to load when this answer is selected.*/
	follow_up_message?: string,
	/**Indicates the correctness of an answer. 0: wrong, 1: neutral, 2: correct.*/
	correctness?: 0 | 1 | 2
	/**References to a message that has to be seen by the player to unlock*/
	conditional_requirement?: string
};

export type StandardMessage = {
	message_type: 0,
	speaking_character: number, 
	answers: Answer[]
};

export type ContextMessage = {
	message_type: 1,
	reset: boolean,
	ellie_emotion: EllieEmotions | undefined
};

export type Character = {
	gender: 'male' | 'female'
	mesh: Meshes
};

export type Item = {
	image: string,
	events: GameEvent[]
};

//#region Event Types

export type GameEvent = {direct?: boolean, fifo?: boolean} & Actions;

type Actions = MessageAction | AnimationAction | MoodStateAction | ObjectStateAction | ObjectVisibilityAction | SceneAction | CameraAction | SoundAction;

type CameraAction = {
	type: 'set-camera-position',
	camera_position_id: number
};
type SoundAction = {
	type: 'play-sound',
	sound: number
};

type MessageAction = {
	type: 'set-message'
	message_id: string
};

type SceneAction = {
	type: 'change-scene'
	scene_id: string
};

type ObjectStateAction = {
	type: 'set-object-state'
	object_id: string, state: number
};

type ObjectVisibilityAction = {
	type: 'set-object-visibility'
	object_id: string, visible: boolean
};

type AnimationAction = {
	type: 'set-character-animation'
	object_id: string, animation: string
};

type MoodStateAction = {
	type: 'set-character-mood'
	object_id: string, mood: Expression
};


//#endregion
