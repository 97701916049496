import { Vector3 } from 'three';
import { CameraSettings } from '../../components/Three/controls/ThreeControls';

export const feedbackSmallMeetingRoomPosition = {
	position: new Vector3(-11.804, 1.2, -0.65),
	target: new Vector3(-10.8, 1, -0.65),
};

export const messageTreeCameraPositions: { [id: string]: CameraSettings } = {

	//#region sf1
	'21': {
		position: new Vector3(-7.7, 1.6, 8.45),
		target: new Vector3(-7, 1.45, 8.7),
	},

	'33': feedbackSmallMeetingRoomPosition,

	'43': feedbackSmallMeetingRoomPosition,
	//#endregion

	//#region sf2

	'78': {
		position: new Vector3(-8.0151, 1.6211, 0.1),
		target: new Vector3(-9., 1.4, 0.5),
	},

	//#endregion

	//#region sf3
	'181': {
		position: new Vector3(-7.88904201, 1.207977, 6.2227735037),
		target: new Vector3(-8.55, 1.1, 7.05),
	},

	'195': {
		position: new Vector3(-7.88904201, 1.207977, 6.2227735037),
		target: new Vector3(-8.55, 1.1, 7.05),
	},

	'315': {
		position: new Vector3(-10.2308, 1.6290375012, 2.094873672),
		target: new Vector3(-11.4, 1.4, 1.5),
	},
	//#endregion

	//#region st1
	'630': {
		position: new Vector3(-2.6506044617228, 1.5849174650900, -8.933371668491079),
		target: new Vector3(-2.3, 1.4, -8),
	},
	'624': {
		position: new Vector3(-0.074687239356, 1.60332017593, -5.972200527259745),
		target: new Vector3(0, 1.3, -5),
	
	},
	//#endregion

	//#region jet
	'1050': {
		position: new Vector3(2.11001, 1.606621323, 3.806107508),
		target: new Vector3(2, 1, 8),
	},
	'1075': {
		position: new Vector3(1.92, 1.606621323, 3.506107508),
		target: new Vector3(2, 1, 8),
	},
	'1500': {
		position: new Vector3(1.9660289, 1.606621323, 3.506107508),
		target: new Vector3(2, 1, 8),
	},
	'1055': {
		position: new Vector3(1.9660289, 1.606621323, 3.506107508),
		target: new Vector3(2, 1, 8),
	},
	'1060': {
		position: new Vector3(-4.9660289, 1.406621323, -1.27),
		target: new Vector3(-4.9, 1, 8),
	},
	'1061': {
		position: new Vector3(-2.9960289, 1.506621323, 1.706107508),
		target: new Vector3(-3, 1, 8),
	},

	//#endregion

	// #region vitality
	
	'2488':{	
		position: new Vector3(-9.25845273, 1.2988865726, 4.51494765),
		target: new Vector3(-11.6, 1.1, 1.7),
	},
	'1155': feedbackSmallMeetingRoomPosition,
	
	'1243': {
		position: new Vector3(-7.7, 1.6, 8.45),
		target: new Vector3(-7, 1.45, 8.7),
	},
	'2099': feedbackSmallMeetingRoomPosition,
	
	'1296': {
		position: new Vector3(-10.2308, 1.6290375012, 1.97),
		target: new Vector3(-11.2, 1.4, 1.5),
	},
	'1302': feedbackSmallMeetingRoomPosition,

	// #endregion

	'3311': {
		position: new Vector3(3.056160796918933, 1.5005433322386412, -4),
		target: new Vector3(3.056160796918933, 1.3, -2.792722723869409)
	},
	
	'3319': {
		position: new Vector3(3.056160796918933, 1.5005433322386412, -4),
		target: new Vector3(3.056160796918933, 1.3, -2.792722723869409)
	},

	'3367': {
		position: new Vector3( 1.7065322893987513, 1.5760907875191523, -3.469723248939579),
		target: new Vector3(3, 1.4, -3.5),
	},

	'3836' : {
		position: new Vector3(1.5, 1.65, -2.2),
		target: new Vector3(1.5, 1.6, -2),
	}
};

