import { Vector3 } from 'three';
import { CameraSettings } from '../../components/Three/controls/ThreeControls';

//positions for Office
export const presetsCameraPositions:{[key:string]:CameraSettings} = {

	//use when one person is in meeting room
	cameraPosition1:{

		position: new Vector3(-7.654461575230385, 1.1940128839697128, 0.20927770359882558),
		target: new Vector3(-7.626248944283985, 1, -1.3192186196365214),
	},

	//use when 1 person is at kitchen.
	cameraPosition2:{

		position: new Vector3(-7.8, 1.6, 7.8),
		target: new Vector3(-7.202475580421008, 1.374066894868852, 8.828399339316148),
	},

	//use when there are 2 characters at the kitchen
	cameraPosition3:{

		position: new Vector3(-7.8, 1.6, 7.8),
		target: new Vector3(-7.8, 1.374066894868852, 8.828399339316148),
	},

	//use when there are 2 characters at the kitchen
	cameraPosition4:{

		position: new Vector3(-10.377519261437252, 1.5, 5.8),
		target: new Vector3(-11.738401439074337, 1, 6.734498226390701),
	},

	//use for callcenter, 1 person behind PC
	cameraPosition5:{

		position: new Vector3(-9.536078526513064, 1.6, 6.465475966468207),
		target: new Vector3(-8.27, 1, 7),
	},

	//use for callcenter, 1 person at watercooler
	cameraPosition6:{

		position: new Vector3(-9.8, 1.6, 8.7),
		target: new Vector3(-8.8, 1.4, 9.7),
	},
    
	//use for callcenter. 2 people at watercooler
	cameraPosition7:{

		position: new Vector3(-9.8, 1.6, 8.3),
		target: new Vector3(-9.6, 1.6, 9.7),
	},

	//use for callcenter. character at pc position 2
	cameraPosition8:{

		position: new Vector3(-9.98, 1.4, 4.1),
		target: new Vector3(-11.6, 1.15, 4.8),
	},

	//use for callcenter. player is at pc
	cameraPosition9:{

		position: new Vector3(-8.3, 1.13, 6.2),
		target: new Vector3(-8.35, 1.1, 5),
	},

	//next segment is for retail - service desk locations
	
	//use for retail service desk. character behind service desk
	cameraPosition10:{

		position: new Vector3(2.9, 1.67, -0.65),
		target: new Vector3(1.5, 1.6, -0.6),
	},

	//use for retail servicedesk - character at shelves
	cameraPosition11:{

		position: new Vector3(-2.2, 1.67, 2.2),
		target: new Vector3(-2.2, 1.6, 5.2),
	},

	//use for retail servicedesk - character behind counter
	cameraPosition12:{

		position: new Vector3(2.9, 1.67, -3.5),
		target: new Vector3(1.5, 1.6, -3.5),
	},

	//use for retail servicedesk - character near entrance
	cameraPosition13:{

		position: new Vector3(0.16, 1.67, -5.2),
		target: new Vector3(-0.5, 1.6, -6.5),
	},

	//next segment is for servicedesk locations

	//use for servicedesk. character behind service desk
	cameraPosition14:{

		position: new Vector3(1.5, 1.6, -2.7),
		target: new Vector3(1.5, 1.5, -2),
	},

	//use for service center. character behind second service desk
	cameraPosition15:{

		position: new Vector3(-1.1, 1.7, -2.8),
		target: new Vector3(-1, 1.6, -1.3),
	},

	//use for service center. character is in store
	cameraPosition16:{

		position: new Vector3(1.82, 1.7, 0.2),
		target: new Vector3(1.82, 1.6, 1.6),
	},

	//use for service center. character sits on couch
	cameraPosition17:{

		position: new Vector3(-0.8, 1.7, 2.1),
		target: new Vector3(-2.56, 1.3, 2.12),
	},

	//use for office and call center. character sits behind pc. player looks over screen.
	cameraPosition18:{

		position: new Vector3(-11.7, 1.32, 6.37),
		target: new Vector3(-11.6, 1.3, 4.76),
	},
	
	
};

