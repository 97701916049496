import { createDirectus, rest } from '@directus/sdk';
import { Scenario, Translations } from '../atoms/content';

export const directus = createDirectus<Schema>('https://tqwi.directus.app').with(rest());
export const assetURL = 'https://tqwi.directus.app/assets/';


type Schema = {
    product_2_content: Content[],
    product_2_scenarios: ScenarioData[],
    product_2_texts: Texts[],
    product_2_texts_translations: TextsTranslations[],
};

// types for data
export type Content = {
	date_created: string,
	scenario: string,
	data: Scenario & {translations?: Translations}
};

type ScenarioData = {
	development_content_package: number,
    production_content_package: number,
    languages: string[]
};

type Texts = {
	id: number,
    key: string,
    translations: number[]
};

type TextsTranslations = {
	id: number,
    text: string,
    product_2_languages_code: string
};