import { SceneData } from '../../../atoms/scene';
import { presetsCameraPositions } from '../../presets/cameraSettings';
import { callCenterPositions } from '../../presets/objectPositions';


const scenes_scenario_73: { [key: string]: SceneData } = {
	'scene1': {
		camera_settings: presetsCameraPositions.cameraPosition18, 

		objects: {
			'5': callCenterPositions.characterPosition5,
		},
	},

};

export default scenes_scenario_73;