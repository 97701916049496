import { SceneData } from '../../../atoms/scene';
import { presetsCameraPositions } from '../../presets/cameraSettings';
import { servicedeskPositions } from '../../presets/objectPositions';


const scenes_scenario_75: { [key: string]: SceneData } = {
	'scene1': {
        camera_settings: presetsCameraPositions.cameraPosition14, 

        objects: {
            '15': {
                ...servicedeskPositions.characterPosition1,
                startMessages: ['3836'],
            } ,
		},
	},

};

export default scenes_scenario_75;
