import { SceneData } from '../../../atoms/scene';
import { presetsCameraPositions } from '../../presets/cameraSettings';
import { officePositions } from '../../presets/objectPositions';


const scenes_scenario_74: { [key: string]: SceneData } = {
	'scene1': {
        camera_settings: presetsCameraPositions.cameraPosition1, 

        objects: {
            '5': officePositions.characterPosition1,
		},
	},

};

export default scenes_scenario_74;
